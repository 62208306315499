import { HttpErrorResponse } from '@angular/common/http';

export function checkAccessTokenExpiry(error: HttpErrorResponse): any {
    // Removed console log
    // Removed console log
    // Removed console log
    if (error.status === 0 || error.status === 401 || error.statusText === 'Unauthorized' || error.statusText === 'Unknown Error') {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
    } else {
        throw error;
    }
}
