
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
// import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
// import { Auth } from 'aws-amplify';
// import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  pageHeading = '';
  userActivity;
  userInactive: Observable<any> = new Observable();
  signedIn;
  sessionTimer;

  constructor(private router: Router) {
    // this.amplifyService.authStateChange$.subscribe(authState => {
    //   //console.log("app component authState", authState);
    //   if (authState.state === 'signedIn') {
    //     this.signedIn = true;
    //   }
    // });

    // this.setUserTimeout();
    // this.userInactive.subscribe(() => {
    //   console.log('comes in');
    //   this.logout();
    // });

    // this.sessionTimer = setInterval(() => {
    //   let accessTokenIssuedAt = localStorage.getItem('issued_at');
    //   if (accessTokenIssuedAt) {
    //     let currentTime = new Date().getTime();
    //     let issuedAt = parseInt(accessTokenIssuedAt);
    //     let timeDiff = currentTime - issuedAt;
    //     let timeDiffInMinutes = timeDiff / (1000 * 60);
    //     if (timeDiffInMinutes > 30) {
    //       this.logout();
    //     }
    //   }
    // }, 2000);
  }

  // logout() {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   this.router.navigate(['/']);
  // }

  // setUserTimeout() {
  //   // console.log('in settimeout');
  //   this.userActivity = setTimeout(() => {
  //     if (this.signedIn) {
  //       // this.userInactive.next(undefined);
  //       console.log('logged out');
  //       this.logout();
  //     }
  //   }, 600000);

  //   //console.log("userActivity", this.userActivity);
  // }

  // @HostListener('window:mousemove') refreshUserState() {
  //   //  console.log('custom directive');
  //   // console.log(this.userActivity);
  //   if (this.userActivity) {
  //     // console.log('cleartimeout');
  //     clearTimeout(this.userActivity);
  //   }
  //   //console.log("HostListener userActivity", this.userActivity);
  //   this.setUserTimeout();
  // }

  // logout() {
  //   this.signout();
  //   // this.authService.redirectLogoutUser();
  // }

  // signout() {
  //   console.log('In method signout');

  //   // Auth.signOut()
  //   //   .then(data => console.log('data ' + data))
  //   //   .catch(err => console.log(err));
  //   window.location.reload();
  // }

  ngOnInit() {
    this.loadAppSettings();
  }

  ngOnDestroy() { }
  loadAppSettings() { }
}
