import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-salesforce-login',
    templateUrl: './salesforce-login.component.html',
    styleUrls: ['./salesforce-login.component.css']
})
export class SalesforceLoginComponent implements OnInit {

    loginFailed: boolean;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.loginFailed = false;
    }

    ngOnInit() {
        // Removed console log
        // Removed console log

        this.handleLogin();
    }

    login() {
        this.handleLogin();
    }


    handleLogin() {
        // Check if 'access_token' is present in local storage
        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            // Navigate to '/trainer-connect' if 'access_token' is present
            this.router.navigate(['/trainer-connect']);
        } else {
            // Use environment variables for client ID and redirect URL
            const salesforceAuthUrl = environment.salesforceAuthUrl;
            const clientId = environment.salesforceClientId;
            const redirectUrl = environment.salesforceRedirectUrl;

            // Redirect to Salesforce login URL
            const salesforceLoginUrl = `${salesforceAuthUrl}?response_type=token&client_id=${clientId}&redirect_uri=${redirectUrl}&state=mystate`;
            window.location.href = salesforceLoginUrl;
        }
    }
}
