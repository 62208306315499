/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { Routes } from '@angular/router';

// import { GenericErrorComponent } from '@alfresco/aca-shared';
import { AuthGuard } from '../app/auth/auth.guard';
import { AdminHomeComponent } from './components/admin-home/admin-home.component';
import { ExpiredTrainerComponent } from './components/expired-trainer/expired-trainer.component';
import { SalesforceLoginComponent } from './components/salesforce-login/salesforce-login.component';
import { SalesforceCallbackComponent } from './components/salesforce-login/salesforce-callback.component';
//import { PaymentRedirectGuard } from './payment-redirect.guard';
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: SalesforceLoginComponent,
    //    canActivate: [PaymentRedirectGuard],
    data: {
      title: 'Sign in'
    }
  },
  {
    path: 'oauth/_callback',
    component: SalesforceCallbackComponent,
    //    canActivate: [PaymentRedirectGuard],
    data: {
      title: 'Sign in'
    }
  },
  {
    path: 'admin-home',
    canActivate: [AuthGuard],
    component: AdminHomeComponent
  },
  {
    path: 'expired-trainer',
    canActivate: [AuthGuard],
    component: ExpiredTrainerComponent
  },
  {
    path: 'trainer-connect',
    canActivate: [AuthGuard],
    loadChildren: () => import(`./components/trainer-connect/trainer-connect.module`).then(
      module => module.TrainerConnectModule
    )
  },
  {
    path: 'TCregistration',
    canActivate: [AuthGuard],
    loadChildren: () => import(`./components/trainer-connect/qbs-registration/registration-qbs.module`).then(
      module => module.RegistrationQbsModule
    )

  },

  {
    path: 'pay',
    //canActivate: [PaymentRedirectGuard],
    loadChildren: () => import(`./components/trainer-connect/registration-payment/eventpayment-qbs.module`).then(
      module => module.EventpaymentQbsModule
    )

  },

  // {
  //   path: '**',
  //   component: GenericErrorComponent
  // }
];
