import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminContactService {
    public contactId;
    public contactAccount;
    public contactName;
    constructor() { }

    setContactId(conId, conName, conAcc) {
        // Removed console log
        this.contactId = conId;
        this.contactName = conName;
        this.contactAccount = conAcc;
        sessionStorage.setItem('conId', this.contactId);
        sessionStorage.setItem('conName', this.contactName);
        sessionStorage.setItem('conAcc', this.contactAccount);
    }

    getContactId() {
        return localStorage.getItem('conn_id');
        // return sessionStorage.getItem('conId');
    }

    getContactAcc() {
        return sessionStorage.getItem('conAcc');
    }

    getContactName() {
        return sessionStorage.getItem('conName');
    }
}
